import React from "react";
import SvgImage, {SvgImageProps} from "../../../common.blocks/Svg/SvgImage";
import SvgObject, {SvgObjectProps} from "../../../common.blocks/Svg/SvgObject";

import "./BannerImage.css";

const bannerClass = "banner__image";

export function BannerImage(props: SvgImageProps){
    props.classList?.push(bannerClass);
    return <SvgImage {...props}/>;
}

export function BannerObjectImage(props: SvgObjectProps){
    props.classList?.push(bannerClass);
    return <SvgObject {...props}/>;
}
