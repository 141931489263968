import React from "react";

import "./HeaderLink.css";

export interface HeaderLinkProps {
    title: string;
    onClick: () => void;
}

export default function HeaderLink({title, onClick}: HeaderLinkProps){
    return <a className="header__link-item" onClick={onClick} >{title}</a>;
}