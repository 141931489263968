import React from "react";
import {BaseComponentProps, classNamesInterface, resolveClassName} from "../../modules/main";

import "./Button.css"

export interface ButtonProps extends BaseComponentProps{
    label: string;
    outlined?: boolean;
}

export default function Button({label="", onClick=()=>{}, outlined=false, classList}:ButtonProps){
    const classNames: classNamesInterface = {
        "button": true,
        "button_outlined": outlined
    };

    return (
        <button
            className={resolveClassName(classNames, classList)}
            onClick={onClick}
        >
            {label}
        </button>
    );
}