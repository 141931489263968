import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {FormField, IFormData, IFormErrors} from "../library.blocks/ContactUs/ContactUs";
import {RootState} from "./index";

interface MainState{
    dialogShow: boolean
    contactsInfo: IFormData
    contactsErrors: IFormErrors
    menuOpened: boolean
}

export interface ContactsField{
    field: FormField
    value: string
}

const initialState: MainState = {
    dialogShow: false,
    contactsInfo: {
        fio: "",
        phone: "",
        email: "",
        message: ""
    },
    contactsErrors: {},
    menuOpened: false
}

const mainSlice = createSlice({
    name: "main",
    initialState,
    reducers:{
        showDialog: (state) => {
            state.dialogShow = true;
        },
        hideDialog: (state) => {
            state.dialogShow = false;
        },
        setFieldValue: (state, action: PayloadAction<ContactsField>) => {
            state.contactsInfo[action.payload.field] = action.payload.value;
        },
        setFieldError: (state, action: PayloadAction<ContactsField>) => {
            state.contactsErrors[action.payload.field] = action.payload.value;
        },
        clearFieldError: (state, action: PayloadAction<FormField>) => {
            if(state.contactsErrors.hasOwnProperty(action.payload))
                delete state.contactsErrors[action.payload];
        },
        clearAllFields: (state) => {
            Object.keys(state.contactsInfo).forEach((key ) => {
                state.contactsInfo[key as FormField] = "";
                delete state.contactsErrors[key as FormField];
            })
        },
        changeMenu: (state) => {
            state.menuOpened = !state.menuOpened;
        }
    }
});

export const {
    showDialog,
    hideDialog,
    setFieldValue,
    setFieldError,
    clearFieldError,
    clearAllFields,
    changeMenu
} = mainSlice.actions;

export const getContactInfo = (state:RootState) => state.main.contactsInfo;
export const getContactErrors = (state:RootState) => state.main.contactsErrors;

export const dialogOpened = (state: RootState) => state.main.dialogShow;

export const menuOpened = (state: RootState) => state.main.menuOpened;

export default mainSlice.reducer;