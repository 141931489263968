import React from "react";
import {SliderProps} from "../SliderProps";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./TabletSlider.css";

export interface TabletSliderProps extends Omit<SliderProps, "height">{
    height?: number
}

export default function TabletSlider({children, autoPlay = false, autoPlayTime = 5000}: TabletSliderProps){

    const settings = {
        className: "center",
        centerPadding: "60px",
        infinite: true,
        slidesToShow: 1,
        centerMode: true,
        dots: true,
        autoplay: autoPlay,
        autoPlaySpeed: autoPlayTime
    };

    return (
        <div className="slider-tablet">
            <Slider {...settings}>
                {children.map((child, index) => <div key={index}>{child}</div>)}
            </Slider>
        </div>
    );
}