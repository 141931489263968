import React, {useEffect} from "react";
import PageSection from "../Page/Section/PageSection";
import PageHeader from "../Page/Header/PageHeader";
import AdvantageCard from "./Card/AdvantageCard";

import {slideUp} from "../../modules/animation";

import "./Advantages.css";
import MobileSlider from "../../common.blocks/Slider/MobileSlider/MobileSlider";
import TabletSlider from "../../common.blocks/Slider/TabletSlider/TabletSlider";

const data = [
    {
        image: "./images/notify.svg",
        title: "Заранее оповестим Вас об окончании полиса"
    },
    {
        image: "./images/fast.svg",
        title: "Оперативно сделаем расчет"
    },
    {
        image: "./images/online.svg",
        title: "Всегда с вами на связи"
    }
]

export default function Advantages(){
    const containerRef = React.useRef<HTMLDivElement>(null);
    const [contentShowed, showContent] = React.useState<boolean>(false);
    const [currentWidth, setCurrentWidth] = React.useState(document.documentElement.clientWidth);

    useEffect(() => {
        if(containerRef.current && document.documentElement.clientWidth > 800){
            slideUp(containerRef.current, ".advantage-card", () => showContent(true));
        }
    }, [containerRef]);

    useEffect(() => {
        window.addEventListener("resize", () => {
            setCurrentWidth(document.documentElement.clientWidth);
        });
    }, [])

    return (
        <PageSection classList={["advantages"]}>
            <PageHeader classList={["advantages__header"]}>
                <span className="text_primary">Аллин ком</span> наши преимущества
            </PageHeader>
            <div className="advantages__content" ref={containerRef}>
                {
                    currentWidth > 800 && data.map(advantage => <AdvantageCard key={advantage.title} {...advantage} showed={contentShowed} />)
                }
                {
                    currentWidth > 480 && currentWidth <= 800 && (
                        <TabletSlider autoPlay={true}>
                            { data.map((advantage, index) => <AdvantageCard key={index} {...advantage} />) }
                        </TabletSlider>
                    )
                }
                {
                    currentWidth <= 480 && (
                        <MobileSlider height={422} autoPlay={true}>
                            { [data[data.length-1], ...data, data[0]].map((advantage, index) => <AdvantageCard key={index} {...advantage} />) }
                        </MobileSlider>
                    )
                }
            </div>
        </PageSection>
    );
}