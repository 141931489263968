import React from "react";
import ParallaxGroup, {ParallaxGroupProps} from "../../common.blocks/Parallax/Group/ParallaxGroup";
import ParallaxLayer from "../../common.blocks/Parallax/Layer/ParallaxLayer";
import Container from "../../common.blocks/Container/Container";
import Offers from "../Offers/Offers";
import Advantages from "../Advantages/Advantages";
import ContactUs from "../ContactUs/ContactUs";
import Questions from "../Questions/Questions";

import "./Page.css";
import Footer from "../Footer/Footer";

export default function Page(props: ParallaxGroupProps){
    return (
        <ParallaxGroup {...props} classList={["page"]}>
            <ParallaxLayer>
                <Container>
                    <Offers />
                    <Advantages />
                    <ContactUs />
                    <Questions />
                </Container>
                <Footer />
            </ParallaxLayer>
        </ParallaxGroup>
    );
}