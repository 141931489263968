import {resolveClassName} from "../../../modules/main";

import "./Dots.css";

export interface DotsProps{
    count: number;
    current: number;
    onChange: (index: number) => void;
}

export default function Dots({count, current, onChange}: DotsProps){

    const clickHandle = (index: number) => () => onChange(index);

    return (
        <div className="slider__dots">
            {
                new Array(count).fill(0).map((z, index) => {
                    const classNames = {
                        "slider__dot": true,
                        "slider__dot_current": index === current
                    }
                    return (
                        <div key={index} className={resolveClassName(classNames)} onClick={clickHandle(index)}/>
                    )
                })
            }
        </div>
    );
}