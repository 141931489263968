import Container from "../../common.blocks/Container/Container";

import {menuLinks} from "../Header/Menu/HeaderMenu";

import phoneImage from "./src/phone.svg";

import "./Footer.css";
import {scrollTo} from "../../common.blocks/Parallax/Parallax";


export default function Footer(){
    const linkClickHandle = (href: string) => () => {
        scrollTo(href);
    };

    return (
        <div className="footer">
            <svg className="footer__line" height="1" xmlns="http://www.w3.org/2000/svg">
                <line x1="4000" y1="0.5" y2="0.5" stroke="#6535EC" strokeDasharray="10 10"/>
            </svg>
            <svg className="footer__plane" width="76" height="67" viewBox="0 0 76 67" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.3373 13.6926L14.2273 24.3411L9.71608 37.8645L15.7825 37.0747L16.7532 45.9974L75.5529 29.4021L12.3373 13.6926Z" fill="#BDD0FB"/>
                <g opacity="0.3" fill="#6535EC">
                    <path d="M15.6792 37.1178L12.961 37.4358L15.2034 29.7144L15.6792 37.1178Z"/>
                    <path d="M14.2273 24.3413L75.5528 29.4022L15.8793 29.1644L9.71601 37.8647L14.2273 24.3413Z"/>
                </g>
                <g fill="#91B3FA">
                    <path d="M75.5529 29.4023C75.5523 29.4342 75.2832 29.4546 74.7711 29.4766C74.1824 29.4906 73.4271 29.5143 72.5247 29.5414C70.4774 29.5654 67.6815 29.6004 64.2842 29.6428C57.2351 29.6586 47.6657 29.6764 37.0921 29.7002C29.0399 29.6581 21.5698 29.6146 15.3658 29.5894L15.6724 29.282C15.7722 34.3243 16.0874 38.4945 16.3376 41.4033C16.4706 42.7811 16.564 43.8959 16.643 44.761C16.7173 45.5428 16.7411 45.959 16.7092 45.9584C16.6772 45.9577 16.5954 45.5596 16.4632 44.7958C16.331 44.0321 16.1677 42.8967 15.9913 41.4477C15.6385 38.5496 15.2275 34.3776 15.0577 29.3147L15.0446 29.0011L15.3643 29.0073C21.569 29.0005 29.0336 28.9928 37.0931 28.9902C47.6523 29.0557 57.2264 29.1212 64.2809 29.1566C67.6763 29.2101 70.4643 29.2517 72.523 29.2983C73.4308 29.3224 74.1722 29.3561 74.7603 29.374C75.2917 29.346 75.5535 29.3703 75.5529 29.4023Z"/>
                    <path d="M75.5529 29.4023C75.5425 29.6068 61.7001 28.6585 44.6422 27.2828C27.5844 25.9071 13.7741 24.6204 13.7845 24.4159C13.7949 24.2114 27.6373 25.1597 44.6952 26.5354C61.7657 27.9178 75.5759 29.2045 75.5529 29.4023Z"/>
                    <path d="M9.71608 37.8647C9.54544 37.7591 10.6723 35.7342 12.2344 33.3725C13.8031 30.9981 15.2014 29.164 15.378 29.289C15.5487 29.3947 14.4217 31.4195 12.8597 33.7813C11.2976 36.143 9.89938 37.9771 9.71608 37.8647Z"/>
                </g>
            </svg>
            <Container classList={["footer__container"]}>
                <div className="footer__column">
                    <div>
                        <div className="footer__logo">Аллин Ком</div>
                        <a className="footer__link" href="mailto:89200759225@mail.ru">89200759225@mail.ru</a>
                    </div>
                </div>
                <div className="footer__column">
                    <div className="footer__links">
                        {
                            menuLinks.map(link => <div key={link.title} className="footer__link" onClick={linkClickHandle(link.href)}>{link.title}</div>)
                        }
                    </div>
                </div>
                <div className="footer__column">
                    <a className="footer__link" href="tel:+79200759225"><img src={phoneImage} alt="" className="footer__phone-img"/>89200759225</a>
                </div>
            </Container>
        </div>
    );
}