import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import TextField from "../../common.blocks/TextField/TextField";

import "./ContactUs.css";
import SvgObject from "../../common.blocks/Svg/SvgObject";
import PageSection from "../Page/Section/PageSection";
import {replaceMask} from "../../modules/main";
import Axios from "axios";
import {
    clearFieldError,
    getContactErrors,
    getContactInfo,
    setFieldError,
    setFieldValue,
    showDialog
} from "../../store/mainSlice";
import {useAppDispatch, useAppSelector} from "../../store";

export interface IFormData{
    fio: string;
    phone: string;
    email: string;
    message: string;
}

export interface IFormErrors{
    fio?: string;
    phone?: string;
    email?: string;
    message?: string;
}

export enum FormField {
    fio = "fio",
    phone = "phone",
    email = "email",
    message = "message",
}

export default function ContactUs(){
    const formData = useAppSelector(getContactInfo);
    const formErrors = useAppSelector(getContactErrors);

    const dispatch = useAppDispatch();

    const [buttonHandled, buttonHandle] = React.useState<boolean>(false);
    const phoneMask = "+7 (___) ___-__-__";

    const validate = (fieldName: FormField, value: string, validate: boolean = false): boolean => {
        let result = true;
        if(buttonHandled || validate){
            const setError = (message: string) => dispatch(setFieldError({field: fieldName, value: message}));
            switch (fieldName){
                case FormField.fio:
                    const fio = value.trim().split(" ");
                    result = fio.length === 3 && !fio.some(part => part.length < 2);
                    if(!result){
                        setError("Укажите Ваши ФИО");
                    }
                    break;
                case FormField.phone:
                    result = value.length === phoneMask.length;
                    if(!result){
                        setError("Формат +7 (XXX) XXX-XX-XX");
                    }
                    break;
                case FormField.email:
                    result = /[^@]+@.+\..+/.test(value.trim());
                    if(!result){
                        setError("Некорректный e-mail");
                    }
                    break;
                case FormField.message:
                    result = value.trim().length > 0
                    if(!result){
                        setError("Пожалуйста, напишите сообщение");
                    }
            }
            if(result && formErrors.hasOwnProperty(fieldName)){
                dispatch(clearFieldError(fieldName));
            }
        }
        return result;
    };

    const handleChange = (fieldName: FormField) => (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let value = event.target.value;
        if(fieldName === FormField.phone){
            value = value.replace(/\D/g, "").replace(/(^78|^77|^7|^8)/g, "");
            value = replaceMask(phoneMask, value);
        }
        validate(fieldName, value);
        dispatch(setFieldValue({field: fieldName, value}));
    }

    const sendButtonHandle = () => {
        buttonHandle(true);
        let validated = true;
        Object.keys(formData).forEach(key => {
            const keyField = key as FormField;
            if(!validate(keyField, formData[keyField], true)){
                validated = false;
            }
        });
        if(validated){
            console.log("validated");
            dispatch(showDialog());
            Axios.post("/sendMail.php", formData);
        }
        else{
            console.log("not validated");
        }
    }

    return (
        <PageSection classList={["contact-us"]}>
            <div className="contact-us__header page__header">
                Напишите нам, и мы перезвоним вам <br />в <span className="text_primary">течение 15 минут</span>
            </div>
            <div className="contact-us__content">
                <SvgObject url="./images/contact.svg" classList={["contact-us__image"]} />
                <div className="contact-us__fields">
                    <div style={{width: "100%"}}>
                        <TextField
                            label="ФИО"
                            onChange={handleChange(FormField.fio)}
                            value={formData[FormField.fio]}
                            error={formErrors.hasOwnProperty(FormField.fio)}
                            helperText={formErrors[FormField.fio]}
                            id="field-fio"
                        />
                        <TextField
                            label="Телефон"
                            onChange={handleChange(FormField.phone)}
                            value={formData[FormField.phone]}
                            error={formErrors.hasOwnProperty(FormField.phone)}
                            helperText={formErrors[FormField.phone]}
                            id="field-phone"
                        />
                        <TextField
                            label="E-mail"
                            onChange={handleChange(FormField.email)}
                            value={formData[FormField.email]}
                            error={formErrors.hasOwnProperty(FormField.email)}
                            helperText={formErrors[FormField.email]}
                            id="field-email"
                            type="email"
                        />
                        <TextField
                            label={<FontAwesomeIcon icon={solid("pen")} className="input-field__label-icon"/>}
                            onChange={handleChange(FormField.message)}
                            value={formData[FormField.message]}
                            error={formErrors.hasOwnProperty(FormField.message)}
                            helperText={formErrors[FormField.message]}
                            id="field-message"
                            multiline
                            rows={6}
                        />
                        <button className="button contact-us__button" onClick={sendButtonHandle}>
                            Отправить
                        </button>
                    </div>
                </div>
            </div>
        </PageSection>
    );
}