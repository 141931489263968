import React, {useEffect} from "react";

import "./Dialog.css";
import Container from "../Container/Container";
import anime from "animejs";
import {resolveClassName} from "../../modules/main";

export interface DialogProps{
    children: React.ReactNode;
    open?: boolean;
    onClose?: () => void;
    actions: JSX.Element | Array<JSX.Element>;
}

export default function Dialog({children, open = true, onClose = () => {}, actions}: DialogProps){
    const backdropRef = React.useRef<HTMLDivElement>(null);
    const contentRef = React.useRef<HTMLDivElement>(null);
    const [localOpen, setLocalOpen] = React.useState<boolean>(open);

    useEffect(() => {
        if(backdropRef && contentRef){
            if(open){
                setLocalOpen(true);
                anime({
                    targets: contentRef.current,
                    scale: [0.75, 1],
                    duration: 200,
                    easing: "easeInOutCubic",
                });
                anime({
                    targets: backdropRef.current,
                    opacity: [0, 1],
                    duration: 150,
                    easing: "linear"
                });
            }
            else{
                anime({
                    targets: contentRef.current,
                    scale: 0.75,
                    duration: 300,
                    easing:"easeInOutCubic"
                });
                anime({
                    targets: backdropRef.current,
                    opacity: 0,
                    duration: 200,
                    easing: "linear",
                    complete: () => setLocalOpen(false)
                });
            }
        }
    }, [backdropRef, contentRef, open]);

    const backdropClasses = {
        "dialog__backdrop": true,
        "dialog__backdrop_hidden": !localOpen
    }

    return (
        <div className={resolveClassName(backdropClasses)} onClick={() => onClose()} aria-hidden="true" ref={backdropRef}>
            <Container classList={["dialog__container"]}>
                <div className="dialog__content" ref={contentRef}>
                    <div style={{width: "100%"}}>
                        { children }
                        <div className="dialog__buttons">
                            { actions }
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
}