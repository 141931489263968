import React from "react";
import anime from "animejs";
import {BaseComponentProps} from "../../modules/main";

import {pageScrollHandle} from "../../modules/animation";

import "./Parallax.css"


export const scrollTo = (className: string) => {
    const parallaxElement = document.querySelector(`.parallax`);
    const element = document.querySelector(`.${className}`);
    if(element && parallaxElement){
        const elementRect = element.getBoundingClientRect();
        anime({
            targets: parallaxElement,
            scrollTop: elementRect.top + parallaxElement.scrollTop,
            easing: "easeInOutCubic"
        })
    }
};

export default function Parallax({ children }:BaseComponentProps){
    const newChildren = React.Children.map(children, (child, index) => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child, { ...child.props, fore: index % 2 === 1});
        }
        return child;
    });

    return (
        <div className="parallax" onScroll={pageScrollHandle}>
            {newChildren}
        </div>
    );
}