import React from "react";

export interface BaseComponentProps {
    classList?: string[],
    style?: React.CSSProperties,
    children?: React.ReactNode,
    onClick?: React.MouseEventHandler;
}

export interface classNamesInterface{
    [key: string]: boolean;
}

export const resolveClassName = (classes: classNamesInterface, additional: string[] = []): string => {
    return [...Object.keys(classes).filter((key: string) => classes[key]), ...additional].join(" ")
}

export const replaceMask = (mask: string, value:string) => {
    let result = mask;
    Array.from(value).forEach(char => {
        result = result.replace("_", char);
    });
    return result.replace(/([^\d_]+)?_(.*?)+$/, "");
}