import React from "react";
import {BaseComponentProps, resolveClassName} from "../../../modules/main";

import "./PageSection.css";

export interface PageSectionProps extends BaseComponentProps{}

export default function PageSection({children, classList}:PageSectionProps){
    const classNames = {
        "page__section": true,
    };

    return (
        <div className={resolveClassName(classNames, classList)}>
            {children}
        </div>
    );
}