import React from "react";
import {BaseComponentProps, resolveClassName} from "../../modules/main";

export interface SvgObjectProps extends BaseComponentProps{
    url: string
}

export default function SvgObject({classList = [], url, ...other}: SvgObjectProps){
    const classNames = {};

    return (
        <object className={resolveClassName(classNames, classList)} type="image/svg+xml" data={url} {...other}>
            <img src={url} alt="" />
        </object>
    );
}


