import HeaderLink from "../Link/HeaderLink";
import React from "react";
import {changeMenu, menuOpened} from "../../../store/mainSlice";
import {scrollTo} from "../../../common.blocks/Parallax/Parallax";
import {useAppDispatch, useAppSelector} from "../../../store";

import {resolveClassName} from "../../../modules/main";

import "./HeaderMenu.css";

export const menuLinks = [
    {
        title: "Услуги",
        href: "offers"
    },
    {
        title: "Контакты",
        href: "contact-us"
    },
    {
        title: "Вопросы",
        href: "questions"
    },
];

export default function HeaderMenu(){
    const menuOpen = useAppSelector(menuOpened);
    const dispatch = useAppDispatch();

    const linksContainerClickHandle = () => {
        if(menuOpen){
            dispatch(changeMenu());
        }
    };

    const linkClickHandle = (href: string) => () => {
        scrollTo(href);
    };

    const menuClasses = {
        "header__menu": true,
        "header__menu_opened": menuOpen
    };

    return (
        <div className={resolveClassName(menuClasses)} onClick={linksContainerClickHandle}>
            {
                menuLinks.map(link => <HeaderLink key={link.title} title={link.title} onClick={linkClickHandle(link.href)} />)
            }
        </div>
    );
}