import React from "react";
import {BaseComponentProps, resolveClassName} from "../../../modules/main";
import Button from "../../../common.blocks/Button/Button";
import OfferCardChip from "./Chip/OfferCardChip";

import './OfferCard.css';

export interface OfferCardProps extends BaseComponentProps{
    image: string;
    title: string;
    description: string;
    chipLabel?: string | null;
    onClick: React.MouseEventHandler;
    showed: boolean;
}

export default function OfferCard({title, image, description, chipLabel=null, classList, onClick, showed}:OfferCardProps){
    const classNames = {
        "offer-card": true,
        "offer-card_focusable": showed,
    };

    return (
        <div className={resolveClassName(classNames, classList)}>
            <img className="offer-card__image" src={image} alt=""/>
            <div className="offer-card__title">
                <span>{title}</span>
                {
                    !!chipLabel && <OfferCardChip label={chipLabel}/>
                }
            </div>
            <div className="offer-card__description">
                {description}
            </div>
            <div className="offer-card__action">
                <Button label="Оформить" onClick={onClick}/>
            </div>
        </div>
    );
}