import React from "react";
import anime from "animejs";

export interface AnimationProps{
    container: HTMLElement;
    target: string;
    viewed: boolean;
    callback: (element: AnimationProps) => void
}

const slideUpElements = new Array<AnimationProps>();

export const slideUp = (container:HTMLElement, target:string, callback:(element: AnimationProps) => void): void => {
    anime.set(target, {translateY: "50%"});
    anime.set(container, {overflow: "hidden"});
    slideUpElements.push({container, target, viewed: false, callback});
};

export const pageScrollHandle = (e: React.UIEvent<HTMLElement>) => {
    slideUpElements.forEach((element) => {
        if(element.container.getBoundingClientRect().top - e.currentTarget.clientHeight + 150 < 0 && !element.viewed){
            element.viewed = true;
            anime({
                targets: element.target,
                translateY: '0%',
                delay: anime.stagger(350),
                duration: 1500,
                easing: 'spring(1, 80, 10, 0)',
                complete: () => {
                    anime.set(element.container, {overflow: "unset"});
                    if(element.callback)
                        element.callback(element);
                }
            })
        }
    });
}