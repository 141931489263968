import React from "react";
import {BaseComponentProps, resolveClassName} from "../../modules/main";

import "./Container.css";

export interface ContainerProps extends BaseComponentProps{}

export default function Container({classList, children}: ContainerProps){
    const classNames = {
        "container": true,
    };

    return (
        <div className={resolveClassName(classNames, classList)}>
            {children}
        </div>
    );

}