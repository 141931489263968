import PageSection from "../Page/Section/PageSection";

import "./Questions.css";
import Accordion from "../../common.blocks/Accordion/Accordion";
import React from "react";

const questionsContent = [
    {
        title: "Осаго",
        expanded: true,
        content: [
            {
                title: "Что даёт полис ОСАГО",
                content: "Если случится ДТП, такой полис покроет нанесенный машинам ущерб и виновному не придется возмещать его из своего кармана. А пострадавшему не придется требовать с виновного средства на ремонт машины лично.",
                expanded: true
            },
            {
                title: "Лимиты  компенсации полиса ОСАГО ",
                content: "",
            },
            {
                title: "Пакет документов для оформления ОСАГО",
                content: "",
            },
            {
                title: "Ответственность за вождение без ОСАГО",
                content: "",
            }
        ]
    },
    {
        title: "Каско",
        content: [
            {
                title: "",
                content: "",
            }
        ]
    },
    {
        title: "Имущество",
        content: [
            {
                title: "",
                content: "",
            }
        ]
    },
    {
        title: "Ипотека",
        content: [
            {
                title: "",
                content: "",
            }
        ]
    },
    {
        title: "Личное страхование",
        content: [
            {
                title: "",
                content: "",
            }
        ]
    },
    {
        title: "Мини каско",
        content: [
            {
                title: "",
                content: "",
            }
        ]
    }
];


export default function Questions(){

    const [expandedRoot, setExpandedRoot] = React.useState<number>(-1);
    const [expandedInner, setExpandedInner] = React.useState<number>(-1);

    const expandRootHandle = (index:number) => () => {
        setExpandedRoot(expandedRoot === index ? -1 : index);
        setExpandedInner(-1);
    };

    const expandInnerHandle = (index:number) => () => {
        setExpandedInner(expandedInner === index ? -1 : index);
    };

    return (
        <PageSection>
            <div className="questions">
                <div className="questions__container">
                    <div className="questions__header page__header">
                        Часто задаваемые вопросы
                    </div>
                    <div className="questions__content">
                        {
                            questionsContent.map((question, qIndex) => (
                                <Accordion
                                    key={qIndex}
                                    {...question}
                                    expanded={qIndex === expandedRoot}
                                    onExpand={expandRootHandle(qIndex)}
                                >
                                    {
                                        question.content.map((accord, index) => (
                                            <Accordion
                                                key={index}
                                                {...accord}
                                                secondary={true}
                                                expanded={qIndex === expandedRoot && expandedInner === index}
                                                onExpand={expandInnerHandle(index)}
                                            >
                                                {accord.content}
                                            </Accordion>
                                        ))
                                    }
                                </Accordion>
                            ))
                        }
                    </div>
                </div>
            </div>
        </PageSection>
    );
}