import React from "react";
import {BaseComponentProps, resolveClassName} from "../../modules/main";

export interface SvgImageProps extends BaseComponentProps{
    viewBox?: string
}

export default function SvgImage({classList, children, ...other}: SvgImageProps){
    const classNames = {};

    return (
        <svg className={resolveClassName(classNames, classList)} {...other} xmlns="http://www.w3.org/2000/svg">
            {children}
        </svg>
    );
}