import {useAppDispatch, useAppSelector} from "../../store";
import {changeMenu, menuOpened} from "../../store/mainSlice";
import {resolveClassName} from "../../modules/main";

import "hamburgers/dist/hamburgers.min.css";
import "./Hamburger.css";

export default function Hamburger(){
    const opened = useAppSelector(menuOpened);
    const dispatch = useAppDispatch();

    const clickHandle = () => dispatch(changeMenu());

    const hamburgerClasses = {
        "hamburger": true,
        "hamburger--spin": true,
        "is-active": opened
    };

    return (
        <button className={resolveClassName(hamburgerClasses)} type="button" onClick={clickHandle}>
            <span className="hamburger-box">
                <span className="hamburger-inner" />
            </span>
        </button>
    );
}