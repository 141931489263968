import React, {ReactNode, useEffect} from "react";
// @ts-ignore
import uniqid from 'uniqid';

import "./TextField.css";
import {resolveClassName} from "../../modules/main";

export interface TextFieldProps {
    multiline?: boolean;
    rows?: number;
    label: string | ReactNode;
    hideLabel?: boolean;
    onChange: React.ChangeEventHandler;
    id: string;
    value?: string;
    helperText?: string;
    error?: boolean;
    [key: string]: any;
}

export default function TextField({onChange, hideLabel, label, multiline, rows=3, value, id, helperText, error=false, ...other}:TextFieldProps){
    const [localValue, setLocalValue] = React.useState<string | undefined>(value);
    const [fieldId] = React.useState<string>(id || uniqid("input-field-"));
    const [focused, setFocus] = React.useState<boolean>(false);

    useEffect(() => {
        setLocalValue(value);
    }, [value])

    const changeHandle = (event:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if(value === undefined){
            setLocalValue(event.target.value || "");
        }
        if (onChange)
            onChange(event);
    }

    const changeFocusHandle = (focused: boolean) => () => setFocus(focused);

    const inputClassNames = {
        "input-field__input": true,
        "input-field__input_focused": focused,
        "input-field__input_wrong": error,
        "input-field__input_textarea": !!multiline,
    };

    const containerClassNames = {
        "input-field": true,
        "input-field_focused": focused,
    }

    const labelClassNames = {
        "input-field__label": true,
        "input-field__label_valid": focused || !!localValue
    }

    const mainContainerClassNames = {
        "text-field": true,
        "text-field_helped": !!helperText
    }

    const inputProps = {
        className: resolveClassName(inputClassNames),
        id: fieldId,
        onChange: changeHandle,
        onBlur: changeFocusHandle(false),
        onFocus: changeFocusHandle(true),
        value: localValue
    }

    return (
        <div className={resolveClassName(mainContainerClassNames)}>
            <div className={resolveClassName(containerClassNames)}>
                {
                    multiline ? (
                        <textarea rows={rows} {...other} {...inputProps} />
                    ) : (
                        <input type="text" {...other} {...inputProps} />
                    )
                }
                <label className={resolveClassName(labelClassNames)} htmlFor={fieldId}>{label}</label>
            </div>
            {
                helperText && (
                    <label className="text-field__helper-text" htmlFor={fieldId}>* {helperText}</label>
                )
            }
        </div>
    );
}