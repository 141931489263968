import React from "react";
import ParallaxGroup, {ParallaxGroupProps} from "../../common.blocks/Parallax/Group/ParallaxGroup";
import ParallaxLayer from "../../common.blocks/Parallax/Layer/ParallaxLayer";
import BannerContainer from "./Container/BannerContainer";
import {BannerImage, BannerObjectImage} from "./Image/BannerImage";
import Button from "../../common.blocks/Button/Button";

import "./Banner.css";
import {scrollTo} from "../../common.blocks/Parallax/Parallax";

export default function Banner(props: ParallaxGroupProps){

    const buttonClickHandle = () => {
        scrollTo("contact-us");
    };

    return (
        <ParallaxGroup {...props} classList={[...props.classList || [], "banner"]}>
            <ParallaxLayer back>
                <BannerContainer>
                    <BannerObjectImage url="/images/screen.svg" classList={["banner__image_screen_small"]} />
                    <BannerObjectImage url="/images/chart.svg" classList={["banner__image_chart"]} />
                </BannerContainer>
            </ParallaxLayer>
            <ParallaxLayer deep>
                <BannerContainer>
                    <BannerObjectImage url="/images/screen.svg" classList={["banner__image_screen"]} />
                </BannerContainer>
            </ParallaxLayer>
            <ParallaxLayer>
                <BannerContainer>
                    <BannerObjectImage url="/images/plant.svg" classList={["banner__image_plant"]} />
                    <BannerImage classList={["banner__image_floor"]} viewBox="0 0 744 2">
                        <path d="M743.2 0.55C743.2 0.71 524.1 1.01 253.74 1.01C-16.63 1.01 -235.94 0.86 -235.94 0.55C-235.94 0.4 -16.84 0.09 253.74 0.09C524.1 0.24 743.2 0.4 743.2 0.55Z" fill="#263238"/>
                    </BannerImage>
                    <BannerObjectImage url="/images/woman.svg" classList={["banner__image_woman"]} />
                    <div className="banner__content">
                        <h1 className="banner__title">Все виды страхования</h1>
                        <Button label="Оформить" classList={["banner__action"]} onClick={buttonClickHandle}/>
                    </div>
                </BannerContainer>
            </ParallaxLayer>
        </ParallaxGroup>
    );
}