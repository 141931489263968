import React, { useEffect, useState } from "react";
import anime from "animejs";
import {SliderProps} from "../SliderProps";

import Dots from "../Controls/Dots";

import "./MobileSlider.css";

export default function MobileSlider({ autoPlay= false, autoPlayTime= 5000, height,  children}: SliderProps){
    const [items, setItems] = React.useState<Array<JSX.Element>>([]);
    const [itemCount, setItemCount] = useState<number>(0);
    const [slide, setSlide] = useState<number>(0);
    const [touchPosition, setTouchPosition] = useState<number | null>(null);
    const spacing = 100;

    const viewportRef = React.useRef<HTMLDivElement>(null);

    useEffect(() => {
        setItemCount(React.Children.count(children) - 2);
        setItems(children);
        setSlideHandle(0);
    }, [children]);

    useEffect(() => {
        if(viewportRef.current) {
            const viewportWidth = viewportRef.current.scrollWidth;
            const slideWidth = (viewportWidth - (items.length - 1) * spacing) / items.length;
            anime.set(viewportRef.current, { translateX: -(slideWidth + spacing) + "px" });
        }
    }, [viewportRef]);

    const setSlideHandle = (slide: number) => {
        if(viewportRef.current){
            const viewportWidth = viewportRef.current.scrollWidth;
            const slideWidth = ((viewportWidth - (items.length - 1) * spacing) / items.length);
            const realSlide = slide + 1;
            anime({
                targets: viewportRef.current,
                easing: "easeInOutCubic",
                translateX: -((slideWidth + spacing) * realSlide) + "px",
            });
            setSlide(slide);
        }
    };

    const changeSlide = (direction: number = 1) => {
        let slideNumber;
        const defaultParams = {
            targets: viewportRef.current,
            easing: "easeInOutCubic"
        }

        if (slide + direction < 0) {
            slideNumber = itemCount - 1;
        } else {
            slideNumber = (slide + direction) % itemCount;
        }


        if(viewportRef.current){
            const viewportWidth = viewportRef.current.scrollWidth;
            const slideWidth = ((viewportWidth - (items.length - 1) * spacing) / items.length);
            if(direction === 1 && slide === itemCount - 1){
                anime({
                    ...defaultParams,
                    translateX: -viewportWidth + slideWidth + "px",
                    complete: () => {
                        anime.set(viewportRef.current, {
                            translateX: - (slideWidth + spacing) + "px"
                        })
                    }
                });
            }
            else if(direction === -1 && slide === 0){
                anime({
                    ...defaultParams,
                    translateX: 0,
                    complete: () => {
                        anime.set(viewportRef.current, {
                            translateX: -(viewportWidth - slideWidth * 2 - spacing) + "px"
                        })
                    }
                });
            }
            else{
                const realSlide = slideNumber + 1;
                anime({
                    ...defaultParams,
                    translateX: -((slideWidth + spacing) * realSlide) + "px",
                });
            }
        }
        setSlide(slideNumber);
    };

    const goToSlide = (number: number) => {
        setSlideHandle(number % itemCount);
    };

    const handleTouchStart = (event: React.TouchEvent) => {
        const touchDown = event.touches[0].clientX;
        setTouchPosition(touchDown);
    }

    const handleTouchMove = (event: React.TouchEvent) => {
        if (touchPosition === null) {
            return;
        }

        const currentPosition = event.touches[0].clientX;
        const direction = touchPosition - currentPosition;

        if (direction > 5) {
            changeSlide(1);
        }

        if (direction < -5) {
            changeSlide(-1);
        }

        setTouchPosition(null);
    }

    useEffect(() => {
        if (!autoPlay) return;

        const interval = setInterval(() => {
            changeSlide(1);
        }, autoPlayTime);

        return () => {
            clearInterval(interval);
        };
    }, [itemCount, slide]);

    return (
        <div
            className="slider"
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
        >
            <div
                className="slider__container"
                style={{height}}
            >
                <div
                    className="slider__viewport"
                    style={{
                        width: `calc(${(items.length * 100)}% + ${((items.length-1) * spacing)}px)`,
                        gridTemplateColumns: `repeat(${items.length}, 1fr)`,
                        gap: spacing
                    }}
                    ref={viewportRef}
                >
                    {items}
                </div>
            </div>
            <Dots
                count={itemCount}
                current={slide}
                onChange={goToSlide}
            />
        </div>
    );
}