import React from "react";
import {BaseComponentProps, resolveClassName} from "../../../modules/main";

import "./ParallaxGroup.css";

export interface ParallaxGroupProps extends BaseComponentProps{
    fore?: boolean;
    name?:string;
}

export default function ParallaxGroup({classList, children, fore = false, ...props}: ParallaxGroupProps){
    const classNames = {
        "parallax__group": true,
        "parallax__group_fore": fore,
    };

    const newChildren = React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
            if(child.props.deep || child.props.back)
                return React.cloneElement(child, { ...child.props, fore});
        }
        return child;
    });

    return (
        <div className={resolveClassName(classNames, classList)} {...props}>
            {newChildren}
        </div>
    );
}