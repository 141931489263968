import React from "react";
import {BaseComponentProps, resolveClassName} from "../../../modules/main";

import "./ParallaxLayer.css";

export interface ParallaxLayerProps extends BaseComponentProps{
    back?: boolean;
    deep?: boolean;
    fore?: boolean;
}

export default function ParallaxLayer({classList, children, back = false, deep = false, fore = false, ...props}: ParallaxLayerProps){
    const classNames = {
        "parallax__layer": true,
        "parallax__layer_back": back && !deep,
        "parallax__layer_fore": fore,
        "parallax__layer_deep": deep,
        "parallax__layer_base": !back && !deep && !fore
    };

    return (
        <div className={resolveClassName(classNames, classList)} {...props}>
            {children}
        </div>
    );
}