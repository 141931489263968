import React, {useEffect} from "react";
import PageSection from "../Page/Section/PageSection";
import PageHeader from "../Page/Header/PageHeader";
import OfferCard from "./OfferCard/OfferCard";

import {slideUp} from "../../modules/animation";

import "./Offers.css";
import {scrollTo} from "../../common.blocks/Parallax/Parallax";

const offers = [
    {
        title: "Осаго",
        image: "./images/osago.svg",
        description: "Осаго - это обязательное страхование гражданской ответственности автомобилиста, то есть тех случаев, когда он стал виновником аварии и теперь должен возместить ущерб другим.",
    },
    {
        title: "Каско",
        image: "./images/kasko.svg",
        description: "Каско – это полис добровольного страхования, покрывающий ущерб при угоне и различных повреждениях ТС.",
    },
    {
        title: "Имущество",
        image: "./images/house.svg",
        description: "ИФЛ — это страхование имущества физических лиц, то есть вашего дома, квартиры, ремонта или чего-то необычного, например, антиквариата, квадроцикла, украшений или благоустроенного сада.",
    },
    {
        title: "Ипотека",
        image: "./images/window.svg",
        description: "Страхование ипотеки – это защита приобретаемого жилья от различных непредвиденных обстоятельств.",
    },
    {
        title: "Личное страхование",
        image: "./images/medicine.svg",
        description: "Предназначено для возмещения ущерба, вызванного потерей здоровья или смертью застрахованного.",
    },
    {
        title: "Мини каско",
        image: "./images/security.svg",
        description: "Мини каско – это недорогая надежная защита вашего автомобиля от крупных рисков, таких как хищение или угон, пожар и других.",
        chipLabel: "1960 ₽",
    }
]


export default function Offers(){
    const containerRef = React.useRef<HTMLDivElement>(null);
    const [contentShowed, showContent] = React.useState<boolean>(false);

    useEffect(() => {
        if(containerRef.current && document.documentElement.clientWidth >= 800){
            slideUp(containerRef.current, ".offer-card", () => showContent(true));
        }
    }, [containerRef]);

    const cardClickHandle = () => {
        scrollTo("contact-us");
    };

    return (
        <PageSection classList={["offers"]}>
            <div className="offers__header">
                <img className="offers__image" src="./images/girl-offers.svg" alt="" />
                <PageHeader classList={["offers__title"]}>Найдем для вас самые выгодные предложения</PageHeader>
            </div>
            <div className="offers__content" ref={containerRef}>
                {
                    offers.map(offer => <OfferCard key={offer.title} {...offer} onClick={cardClickHandle} showed={contentShowed} />)
                }
            </div>
        </PageSection>
    );
}