import React from "react";
import {BaseComponentProps, resolveClassName} from "../../../modules/main";

import "./PageHeader.css";

export default function PageHeader({children, classList}:BaseComponentProps){
    const classNames = {
        "page__header": true,
    };

    return (
        <h2 className={resolveClassName(classNames, classList)}>
            {children}
        </h2>
    );
}