import React from "react";
import {BaseComponentProps, resolveClassName} from "../../../modules/main";

import "./AdvantageCard.css";

export interface AdvantageCardProps extends BaseComponentProps{
    image: string;
    title: string;
    showed?: boolean;
}

export default function AdvantageCard({image, title, showed = true, classList}:AdvantageCardProps){
    const classNames = {
        "advantage-card": true,
        "advantage-card_focusable": showed,
    };

    return (
        <div className={resolveClassName(classNames, classList)}>
            <div className="advantage-card__image-container">
                <img src={image} alt="" className="advantage-card__image" />
            </div>
            <div className="advantage-card__text">{title}</div>
        </div>
    );
}