import React from "react";

import "./OfferCardChip.css";

import star from "./star.svg";

export interface OfferCardChipProps {
    label: string;
}

export default function OfferCardChip({label}:OfferCardChipProps){
    return (
        <span className="offer-card__chip">
            {label}
            <img className="offer-card__chip-star" src={star} alt=""/>
        </span>
    );
}