import React from "react";
import Container from "../../common.blocks/Container/Container";
import Button from "../../common.blocks/Button/Button";
import Hamburger from "../../common.blocks/Hamburger/Hamburger";

import {scrollTo} from "../../common.blocks/Parallax/Parallax";

import "./Header.css";
import HeaderMenu from "./Menu/HeaderMenu";

export default function Header(){

    const buttonClickHandle = () => {
        scrollTo("contact-us");
    };

    return (
        <header className="header">
            <Container classList={["header__container"]}>
                <a className="header__logo" href="/">Аллин Ком</a>
                <HeaderMenu />
                <div className="header-right-view">
                    <Button label="Оформить" classList={["header__button"]} outlined onClick={buttonClickHandle}/>
                    <div className="header__burger">
                        <Hamburger />
                    </div>
                </div>
            </Container>
        </header>
    );
}