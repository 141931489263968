import React from 'react';
import Header from "./library.blocks/Header/Header";
import Parallax from "./common.blocks/Parallax/Parallax";
import Banner from "./library.blocks/Banner/Banner";
import Page from "./library.blocks/Page/Page";
import Dialog from "./common.blocks/Dialog/Dialog";
import Button from "./common.blocks/Button/Button";
import {useAppDispatch, useAppSelector} from "./store";
import {clearAllFields, dialogOpened, hideDialog} from "./store/mainSlice";

function App() {
    const dialogShow = useAppSelector(dialogOpened);
    const dispatch = useAppDispatch();

    const hideDialogHandle = () => {
        dispatch(hideDialog());
        dispatch(clearAllFields());
    };

    return (
        <>
            <Header />
            <Parallax>
                <Banner />
                <Page />
            </Parallax>
            <Dialog open={dialogShow} onClose={hideDialogHandle} actions={<Button label="Хорошо" onClick={hideDialogHandle}/>}>
                <div>Ваше письмо успешно отправлено!</div>
                <div>Мы свяжемся с Вами <span className="text_primary">в течение 15 минут</span></div>
            </Dialog>
        </>
    );
}

export default App;
