import "./Accordion.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import React, {useEffect} from "react";
import {resolveClassName} from "../../modules/main";
import anime from "animejs";

export interface AccordionProps{
    title: string;
    expanded?: boolean;
    onExpand?: (expand: boolean) => void;
    secondary?: boolean;
    children: React.ReactNode
}

export default function Accordion({title, expanded=false, onExpand = ()=>{}, secondary = false, children}:AccordionProps){
    const [localExpand, setLocalExpand] = React.useState(expanded);
    const contentNode = React.useRef<HTMLDivElement>(null);
    const contentDataNode = React.useRef<HTMLDivElement>(null);

    useEffect(() => {
        setLocalExpand(expanded);
    }, [expanded])

    const angleClassNames = {
        "accordion__header-angle": true,
        "accordion__header-angle_expanded": localExpand
    }

    const accordionClassNames = {
        "accordion": true,
        "accordion_theme_secondary": secondary
    }

    const accordionContentClassNames = {
        "accordion__content": true,
        "accordion__content_hidden": !localExpand
    }

    useEffect(()=> {
        if(contentNode.current && contentDataNode.current){
            if(!localExpand)
                contentNode.current.style.height = "";

            anime({
                targets: contentNode.current,
                height: localExpand ? [0, contentDataNode.current.clientHeight] : [contentNode.current.clientHeight, 0],
                duration: localExpand ? 300 : 150,
                easing: 'linear',
                complete: () => {
                    setTimeout(() => {
                        if(localExpand && contentNode.current)
                            contentNode.current.style.height = "auto";
                    }, 100)
                }
            });
        }

    }, [localExpand, contentNode, contentDataNode])

    const expandHandle = () => {
        const newExpand = !localExpand
        onExpand(newExpand);
        setLocalExpand(newExpand);
    }

    return (
        <div className={resolveClassName(accordionClassNames)}>
            <div className="accordion__header" onClick={expandHandle}>
                <span className="accordion__header-text">{title}</span>
                <FontAwesomeIcon icon={solid("angle-down")} className={resolveClassName(angleClassNames)} />
            </div>
            <div className={resolveClassName(accordionContentClassNames)} ref={contentNode}>
                <div className="accordion__content-data" ref={contentDataNode}>
                    {children}
                </div>
            </div>
        </div>
    );
}